import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const Flag = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &.active {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    border: 2px solid #fff;
  }
`;

export default function FlagSelector({
  flag,
  setFlag,
  topMargin,
  bottomMargin,
}) {
  const flags = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return (
    <Holder
      style={{
        marginTop: topMargin ? topMargin : 0,
        marginBottom: bottomMargin ? bottomMargin : 0,
      }}
    >
      {flags.map((_flag) => (
        <Flag
          key={_flag}
          className={flag === _flag && "active"}
          onClick={() => {
            setFlag(_flag);
          }}
        >
          <img src={`/img/flags/flag${_flag}.svg`} alt="Bandeira" />
        </Flag>
      ))}
    </Holder>
  );
}
