import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Wrapper from "../../components/user/wrapper";
import Title from "../../components/user/title";
import SubMenu from "../../components/user/submenu";
import RankingDetail from "../../components/user/ranking";

const Holder = styled.div`
  padding: 48px;
  height: 100%;
  position: relative;
  flex: 1;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

const Hide = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export default function Ranking() {
  const [user, setUser] = useState();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      backgroundLeft={"#000"}
      backgroundRight={"#111"}
      footerType={0}
      login={true}
      loginCallback={setUser}
    >
      <Holder>
        <Title color={"#fff"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clip-path="url(#clip0_240_66030)">
              <path
                d="M28.3424 9.1424C27.8368 9.1424 27.4288 8.7328 27.4288 8.2288V5.9424C27.4288 5.1856 26.8144 4.5712 26.0576 4.5712H23.7712C23.2656 4.5712 22.8576 4.1616 22.8576 3.6576V0H9.1424V3.6576C9.1424 4.1632 8.7328 4.5712 8.2288 4.5712H5.9424C5.1856 4.5712 4.5712 5.1856 4.5712 5.9424V8.2288C4.5712 8.7344 4.1616 9.1424 3.6576 9.1424H0V13.7136H3.6576C4.1632 13.7136 4.5712 14.1232 4.5712 14.6272V16.9136C4.5712 17.6704 5.1856 18.2848 5.9424 18.2848H8.2288C8.7344 18.2848 9.1424 18.6944 9.1424 19.1984V21.4848C9.1424 22.2416 9.7568 22.856 10.5136 22.856H12.8C13.3056 22.856 13.7136 23.2656 13.7136 23.7696V26.512C13.7136 27.0176 13.304 27.4256 12.8 27.4256H10.5136C9.7568 27.4256 9.1424 28.04 9.1424 28.7968V31.9968H22.856V28.7968C22.856 28.04 22.2416 27.4256 21.4848 27.4256H19.1984C18.6928 27.4256 18.2848 27.016 18.2848 26.512V23.7696C18.2848 23.264 18.6944 22.856 19.1984 22.856H21.4848C22.2416 22.856 22.856 22.2416 22.856 21.4848V19.1984C22.856 18.6928 23.2656 18.2848 23.7696 18.2848H26.056C26.8128 18.2848 27.4272 17.6704 27.4272 16.9136V14.6272C27.4272 14.1216 27.8368 13.7136 28.3408 13.7136H31.9984V9.1424H28.3408H28.3424ZM27.4288 12.8C27.4288 13.3056 27.0192 13.7136 26.5152 13.7136H24.2288C23.472 13.7136 22.8576 14.328 22.8576 15.0848V17.3712C22.8576 17.8768 22.448 18.2848 21.944 18.2848H19.6576C18.9008 18.2848 18.2864 18.8992 18.2864 19.656V21.9424C18.2864 22.448 17.8768 22.856 17.3728 22.856H14.6304C14.1248 22.856 13.7168 22.4464 13.7168 21.9424V19.656C13.7168 18.8992 13.1024 18.2848 12.3456 18.2848H10.0592C9.5536 18.2848 9.1456 17.8752 9.1456 17.3712V15.0848C9.1456 14.328 8.5312 13.7136 7.7744 13.7136H5.488C4.9824 13.7136 4.5744 13.304 4.5744 12.8V10.0576C4.5744 9.552 4.984 9.144 5.488 9.144H7.7744C8.5312 9.144 9.1456 8.5296 9.1456 7.7728V5.4864C9.1456 4.9808 9.5552 4.5728 10.0592 4.5728H21.9456C22.4512 4.5728 22.8592 4.9824 22.8592 5.4864V7.7728C22.8592 8.5296 23.4736 9.144 24.2304 9.144H26.5168C27.0224 9.144 27.4304 9.5536 27.4304 10.0576V12.8H27.4288Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_240_66030">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Ranking
        </Title>
        <br />
        <Hide>
          <RankingDetail user={user} />
        </Hide>
        <Bottom>
          <SubMenu page={1} />
        </Bottom>
      </Holder>
      <RankingDetail user={user} />
    </Wrapper>
  );
}
