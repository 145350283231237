import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #000;
  padding: 24px 16px;
  border-top: 1px solid rgba(111, 111, 111, 0.4);
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  &.active,
  &:hover {
    text-decoration-line: underline;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const ItemDot = styled.div`
  background: #fff;
  width: 10px;
  height: 10px;
  margin-right: 8px;
`;

export default function SubMenu({ page }) {
  const navigate = useNavigate();

  return (
    <Holder>
      <Item
        className={page === 0 && "active"}
        onClick={() => {
          navigate("/user/tickets");
        }}
      >
        <ItemDot className={page === 0 && "active"} />
        Reservas
      </Item>
      <Item
        className={page === 1 && "active"}
        onClick={() => {
          navigate("/user/ranking");
        }}
      >
        <ItemDot className={page === 1 && "active"} />
        Ranking
      </Item>
    </Holder>
  );
}
