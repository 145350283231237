import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-top: 16px;

  @media (max-width: 1024px) {
    font-size: 24px;
    margin-top: 8px;
  }
`;

export default function Text({ children, color }) {
  return <Holder style={{ color: color ? color : "#000" }}>{children}</Holder>;
}
