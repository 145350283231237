import React from "react";
import styled from "styled-components";
import Page from "../page";

const Main = styled.div`
    background: #111;
`;

const Holder = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 1024px) {
        display: block;
        padding: 16px;
    }
`;

const Block = styled.div`
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const BlueBlock = styled.div`
    width: 120px;
    height: 120px;
    background: #344bff;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    height: 624px;

    @media (max-width: 1024px) {
        height: auto;
    }
`;

const ContentBlock = styled.div`
    width: 100%;
    height: 504px;
    background: #344bff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        height: auto;
    }
`;

const ContentText = styled.div`
    padding: 40px 48px 0px;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 30px */

    h1 {
        line-height: 100%;
    }

    @media (max-width: 1024px) {
        padding-bottom: 24px;
    }
`;
const ContentLink = styled.a`
    width: 100%;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px; /* 233.333% */
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    justify-content: start;
    background: #00f088;
    padding: 16px 48px;

    img {
        margin-left: 16px;
    }

    @media (max-width: 1024px) {
        font-size: 16px;
        justify-content: center;

        img {
            height: 20px;
        }
    }
`;

const Photo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    height: 624px;
    @media (max-width: 1024px) {
        height: 250px;
    }
`;

const Image1 = styled.div`
    width: 100%;
    height: 504px;
    background-image: url(/img/infinito.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

const Image2 = styled.div`
    width: 100%;
    height: 504px;
    background-image: url(/img/prisma.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

export default function Events() {
    return (
        <Main>
            <Page>
                <Holder>
                    <Block>
                        <BlueBlock />
                    </Block>
                    <Content>
                        <ContentBlock>
                            <ContentText>
                                <h1>Salão Infinito</h1>
                                <br />O Salão Infinito tem capacidade para até
                                60 pessoas, cenografia inovadora com diversos
                                portais que te levam à infinidade! O salão conta
                                com 2 jogos exclusivos e inéditos: Duel e
                                Multiball que incluem mais de 40 jogos e farão
                                seu evento ser único. <br />
                                <br />
                                Traga seu evento para cá, fale com a gente
                            </ContentText>
                            <ContentLink
                                href="https://wa.me/5511995030111"
                                target="_blank"
                            >
                                (11) 99503-0111
                                <img
                                    src="/img/whatsapp.svg"
                                    alt="faleconosco@pixel.com.br"
                                />
                            </ContentLink>
                        </ContentBlock>
                    </Content>
                    <Photo>
                        <Image1 />
                    </Photo>
                </Holder>
                <Holder>
                    <Block>
                        <BlueBlock />
                    </Block>
                    <Content>
                        <ContentBlock>
                            <ContentText>
                                <h1>Espaço Prisma</h1>
                                <br />O Espaço Prisma tem capacidade para até 80
                                pessoas, pintura diferenciada e vista de dar
                                inveja! Conta com o jogo Lines, único no Brasil,
                                uma varanda com bistrôs e projetor, pronto para
                                receber todos os estilos de festa! <br />
                                <br />
                                Traga seu evento para cá, fale com a gente
                            </ContentText>
                            <ContentLink
                                href="https://wa.me/5511995030111"
                                target="_blank"
                            >
                                (11) 99503-0111
                                <img
                                    src="/img/whatsapp.svg"
                                    alt="faleconosco@pixel.com.br"
                                />
                            </ContentLink>
                        </ContentBlock>
                    </Content>
                    <Photo>
                        <Image2 />
                    </Photo>
                </Holder>
            </Page>
        </Main>
    );
}
