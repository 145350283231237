import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;
`;

const Flag = styled.div`
  display: flex;
  background: #000;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 24px;
  }

  cursor: pointer;

  &:hover,
  &.active {
    background: #fff;
  }
`;

const FlagName = styled.div`
  width: 100%;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: 188px;
  flex: 1;
  word-break: break-all;

  &:hover,
  &.active {
    color: #000;

    div {
      background: #000;
      color: #fff;
    }
  }

  @media (max-width: 1024px) {
    height: 144px;
    padding: 16px;
  }
`;

const Players = styled.div`
  border-radius: 29px;
  background: #fff;
  padding: 8px 16px;
  color: #000;
  font-size: 16px;
  @media (max-width: 1024px) {
    padding: 0px 16px;
  }
`;

const FlagImage = styled.img`
  width: 188px;
  height: 188px;

  @media (max-width: 1024px) {
    height: 144px;
    width: 144px;
  }
`;

export default function TeamSelector({
  teams,
  selectedTeam,
  setSelectedTeam,
  setTeam,
}) {
  return (
    <Holder>
      {teams.map((team, index) => (
        <Flag
          key={index}
          onClick={() => {
            setTeam(team.name);
            setSelectedTeam(team.hash);
          }}
        >
          <FlagName>
            <Players>
              {team.active_players === 0
                ? "Nenhum Player"
                : team.active_players === 1
                ? "1 Player"
                : `${team.active_players} Players`}
            </Players>
            {team.name}
          </FlagName>
          <FlagImage src={`/img/flags/flag${team.flag}.svg`} alt="Bandeira" />
        </Flag>
      ))}
    </Holder>
  );
}
