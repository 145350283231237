import React from "react";
import styled from "styled-components";

import GameHeader from "../blocks/game_header";
import GameInfo from "../blocks/game_info";
import HitPlayable from "./playable/hit";

const Holder = styled.div``;

export default function Hit() {
    return (
        <Holder>
            <GameHeader
                title="HIT"
                video="/videos/hit.mp4"
                rotation={-3}
                bottom={160}
                icon="/img/game_logo_hit.svg"
                color1="#344BFF"
                color2="#00F088"
            />
            <GameInfo
                icon="/img/icon_5.svg"
                color1="#344BFF"
                title={"Hit"}
                content={
                    "Tiro ao alvo ou boca do palhaço, como você preferir! É bom de mira? Se não, selecione os melhores para o seu time."
                }
                habilidades={["Velocidade", "Raciocinio", "Precisão"]}
                regras={[
                    "Time de 2 a 6 pessoas",
                    "Treine no faroeste",
                    "Todas as idades",
                ]}
            >
                <HitPlayable />
            </GameInfo>
        </Holder>
    );
}
