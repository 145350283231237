import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Api from "../../libs/api";
import Storage from "../../libs/storage";
import { useNavigate } from "react-router-dom";

const Holder = styled.div`
  padding: 0px;
  width: 100%;
  padding-bottom: 0px;

  @media (max-width: 1024px) {
    padding-bottom: 72px;
  }
`;

const Label = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 30px */

  padding: 64px;

  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 1024px) {
    padding: 0px;
    padding-bottom: 32px;
  }
`;

const Tabs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Tab = styled.div`
  width: 100%;
  color: var(--Cores-Branco, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  height: 30px;
  cursor: pointer;

  &.active {
    border-bottom: 1px solid #fff;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.team {
    background-color: #344bff !important;
  }
`;
const Position = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 80px;

  &.team {
    font-weight: bold;
    color: #fff;
    background-color: #344bff !important;
  }
`;
const Name = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  padding: 0px 16px 0px 0px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  &.team {
    font-weight: bold;
    color: #fff;
    background-color: #344bff !important;
  }
`;
const Points = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 20px */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 80px;

  &.team {
    font-weight: bold;
    color: #fff;
    background-color: #344bff !important;
  }
`;

const Month = styled.div`
  width: fit-content;
  color: #000;
  background: #fff;
  border-radius: 100px;
  padding: 8px 16px;
  margin-left: 16px;

  color: var(--Cores-Preto, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

const Rooms = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 32px;

  @media (max-width: 1024px) {
    padding: 32px 0px;
  }
`;
const Room = styled.div`
  width: fit-content;
  color: #000;
  background: #fff;
  border-radius: 100px;
  padding: 8px 16px;
  margin-right: 16px;

  color: var(--Cores-Preto, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  cursor: pointer;

  &:hover,
  &.active {
    background: #000;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 15px;
  }
`;

export default function RankingDetail() {
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);
  const [room, setRoom] = useState(0);
  const [data, setData] = useState({
    overall: [],
    players: [],
    rooms: {
      dank: [],
      hit: [],
      laser: [],
      megapixel: [],
      quest: [],
    },
  });

  const labels = ["times", "players", "salas"];

  const api = Api();
  const storage = Storage();

  const getData = async () => {
    const token = storage.getItem("token");
    if (token) {
      const _data = await api.getRanking(token);
      setData(_data);
    } else {
      navigate("/user/login");
    }
  };

  const getTwoColors = () => {
    const colors = ["#FF4824", "#F471FD", "#EBDF35", "#00F088", "#344BFF"];
    const firstIndex = Math.floor(Math.random() * colors.length);
    let secondIndex;
    do {
      secondIndex = Math.floor(Math.random() * colors.length);
    } while (secondIndex === firstIndex);
    return [colors[firstIndex], colors[secondIndex]];
  };

  function getNomeMesAtual() {
    const meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const mesAtual = new Date().getMonth();
    return meses[mesAtual];
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Holder>
      <Label>
        Ranking por {labels[tab]} <Month>{getNomeMesAtual()}</Month>
      </Label>
      <Tabs>
        <Tab
          className={tab === 0 ? "active" : null}
          onClick={() => {
            setTab(0);
          }}
        >
          Times
        </Tab>
        <Tab
          className={tab === 1 ? "active" : null}
          onClick={() => {
            setTab(1);
          }}
        >
          Players
        </Tab>
        <Tab
          className={tab === 2 ? "active" : null}
          onClick={() => {
            setTab(2);
          }}
        >
          Salas
        </Tab>
      </Tabs>
      <Content>
        {tab === 0 &&
          data.overall.map((ranking) => {
            const colors = getTwoColors();
            return (
              <>
                <Line
                  className={ranking.my_team ? "team" : null}
                  style={{ background: colors[0] }}
                >
                  <Position
                    className={ranking.my_team ? "team" : null}
                    style={{ background: colors[0] }}
                  >
                    {ranking.position}
                  </Position>
                  <Name
                    className={ranking.my_team ? "team" : null}
                    style={{ background: colors[0] }}
                  >
                    {ranking.team}
                  </Name>
                  <Points
                    className={ranking.my_team ? "team" : null}
                    style={{ background: colors[1] }}
                  >
                    {ranking.total} pts
                  </Points>
                </Line>
              </>
            );
          })}
        {tab === 1 &&
          data.players.map((ranking) => {
            const colors = getTwoColors();
            return (
              <>
                <Line style={{ background: colors[0] }}>
                  <Position style={{ background: colors[0] }}>
                    {ranking.position}
                  </Position>
                  <Name style={{ background: colors[0] }}>
                    {ranking.player.name}
                  </Name>
                  <Points style={{ background: colors[1] }}>
                    {ranking.total} pts
                  </Points>
                </Line>
              </>
            );
          })}
        {tab === 2 && (
          <>
            <Rooms>
              <Room
                className={room === 0 ? "active" : null}
                onClick={() => {
                  setRoom(0);
                }}
              >
                HIT
              </Room>
              <Room
                className={room === 1 ? "active" : null}
                onClick={() => {
                  setRoom(1);
                }}
              >
                LASER
              </Room>
              <Room
                className={room === 2 ? "active" : null}
                onClick={() => {
                  setRoom(2);
                }}
              >
                QUEST
              </Room>
              <Room
                className={room === 3 ? "active" : null}
                onClick={() => {
                  setRoom(3);
                }}
              >
                DANK
              </Room>
              <Room
                className={room === 4 ? "active" : null}
                onClick={() => {
                  setRoom(4);
                }}
              >
                MEGAPIXEL
              </Room>
            </Rooms>
            {room === 0 &&
              data.rooms.hit.map((ranking) => {
                const colors = getTwoColors();
                return (
                  <>
                    <Line style={{ background: colors[0] }}>
                      <Position style={{ background: colors[0] }}>
                        {ranking.position}
                      </Position>
                      <Name style={{ background: colors[0] }}>
                        {ranking.name}
                      </Name>
                      <Points style={{ background: colors[1] }}>
                        {ranking.total} pts
                      </Points>
                    </Line>
                  </>
                );
              })}
            {room === 1 &&
              data.rooms.laser.map((ranking) => {
                const colors = getTwoColors();
                return (
                  <>
                    <Line style={{ background: colors[0] }}>
                      <Position style={{ background: colors[0] }}>
                        {ranking.position}
                      </Position>
                      <Name style={{ background: colors[0] }}>
                        {ranking.name}
                      </Name>
                      <Points style={{ background: colors[1] }}>
                        {ranking.total} pts
                      </Points>
                    </Line>
                  </>
                );
              })}
            {room === 2 &&
              data.rooms.quest.map((ranking) => {
                const colors = getTwoColors();
                return (
                  <>
                    <Line style={{ background: colors[0] }}>
                      <Position style={{ background: colors[0] }}>
                        {ranking.position}
                      </Position>
                      <Name style={{ background: colors[0] }}>
                        {ranking.name}
                      </Name>
                      <Points style={{ background: colors[1] }}>
                        {ranking.total} pts
                      </Points>
                    </Line>
                  </>
                );
              })}
            {room === 3 &&
              data.rooms.megapixel.map((ranking) => {
                const colors = getTwoColors();
                return (
                  <>
                    <Line style={{ background: colors[0] }}>
                      <Position style={{ background: colors[0] }}>
                        {ranking.position}
                      </Position>
                      <Name style={{ background: colors[0] }}>
                        {ranking.name}
                      </Name>
                      <Points style={{ background: colors[1] }}>
                        {ranking.total} pts
                      </Points>
                    </Line>
                  </>
                );
              })}
            {room === 4 &&
              data.rooms.quest.map((ranking) => {
                const colors = getTwoColors();
                return (
                  <>
                    <Line style={{ background: colors[0] }}>
                      <Position style={{ background: colors[0] }}>
                        {ranking.position}
                      </Position>
                      <Name style={{ background: colors[0] }}>
                        {ranking.name}
                      </Name>
                      <Points style={{ background: colors[1] }}>
                        {ranking.total} pts
                      </Points>
                    </Line>
                  </>
                );
              })}
          </>
        )}
      </Content>
    </Holder>
  );
}
