import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Header from "../user/header";
import Api from "../../libs/api";
import Storage from "../../libs/storage";

const Holder = styled.div`
  padding: 0px;
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  width: 100%;
  z-index: 1;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  z-index: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const LeftFooter1 = styled.div`
  width: 100%;
  height: 120px;
  background: #000;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SquareFooter1 = styled.div`
  width: 120px;
  height: 120px;
  background: #ebdf35;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const LeftFooter2 = styled.div`
  width: 100%;
  height: 120px;
  background: #344bff;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SquareFooter2 = styled.div`
  width: 120px;
  height: 120px;
  background: #ebdf35;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const LeftFooter3 = styled.div`
  width: 100%;
  height: 120px;
  background: #344bff;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SquareFooter3 = styled.div`
  width: 120px;
  height: 120px;
  background: #ff4824;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const SquareFooter3Extra = styled.div`
  width: 120px;
  height: 120px;
  background: #ebdf35;
  position: absolute;
  top: 0px;
  left: 120px;
`;

const RightFooter1 = styled.div`
  width: 100%;
  height: 120px;
  background: #ff4824;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const RightFooter1Image = styled.img`
  width: 182px;
  height: 182px;
  position: absolute;
  right: 0px;
  top: -182px;
`;

const RightFooter2 = styled.div`
  width: 100%;
  height: 120px;
  background: #ff4824;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const RightFooter2Image = styled.img`
  width: 182px;
  height: 182px;
  position: absolute;
  left: 0px;
  top: -182px;
`;

const RightFooter3 = styled.div`
  width: 100%;
  height: 120px;
  background: #f471fd;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const RightFooter3Image = styled.img`
  width: 182px;
  height: 182px;
  position: absolute;
  right: 0px;
  top: -182px;
`;

export default function Wrapper({
  children,
  backgroundLeft,
  backgroundRight,
  footerType,
  user,
  login,
  loginCallback,
}) {
  const api = Api();
  const storage = Storage();
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight - 80);

  // Convert children to an array to handle multiple children
  const childrenArray = React.Children.toArray(children);

  // Extract the first and second child
  const firstChild = childrenArray[0];
  const secondChild = childrenArray[1];

  const getData = async () => {
    const token = storage.getItem("token");
    if (token) {
      const user = await api.getUser(token);
      if (!user.username) {
        navigate("/user/login");
      } else {
        if (loginCallback) {
          loginCallback(user);
        }
      }
    } else {
      navigate("/user/login");
    }
  };

  const fixContentHeight = () => {
    const left = document.getElementById("left");
    const right = document.getElementById("right");

    const height1 = left.clientHeight;
    const height2 = right.clientHeight;

    if (height1 > height2) {
      right.style.minHeight = height1 + "px";
    } else {
      left.style.minHeight = height2 + "px";
    }
  };

  useEffect(() => {
    fixContentHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight - 80);
      fixContentHeight();
    });

    fixContentHeight();

    if (login) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header user={user} />
      <Holder
        style={{
          minHeight: height,
        }}
      >
        <Left
          style={{
            minHeight: height,
            background: backgroundLeft ? backgroundLeft : "#000",
          }}
        >
          <Content
            id="left"
            style={{
              minHeight:
                height - (width < 1024 ? 0 : footerType === 0 ? 0 : 120),
            }}
          >
            {firstChild}
          </Content>
          {footerType === 1 && (
            <LeftFooter1>
              <SquareFooter1 />
            </LeftFooter1>
          )}
          {footerType === 2 && (
            <LeftFooter2>
              <SquareFooter2 />
            </LeftFooter2>
          )}
          {footerType === 3 && (
            <LeftFooter3>
              <SquareFooter3 />
              <SquareFooter3Extra />
            </LeftFooter3>
          )}
        </Left>
        <Right
          style={{
            minHeight: height,
            background: backgroundRight ? backgroundRight : "#000",
          }}
        >
          <Content
            id="right"
            style={{
              minHeight:
                height - (width < 1024 ? 0 : footerType === 0 ? 0 : 120),
            }}
          >
            {secondChild}
          </Content>
          {!secondChild && (
            <>
              {footerType === 1 && (
                <RightFooter1>
                  <RightFooter1Image src="/img/user/footer1.svg" />
                </RightFooter1>
              )}
              {footerType === 2 && (
                <RightFooter2>
                  <RightFooter2Image src="/img/user/footer2.svg" />
                </RightFooter2>
              )}
              {footerType === 3 && (
                <RightFooter3>
                  <RightFooter3Image src="/img/user/footer2.svg" />
                </RightFooter3>
              )}
            </>
          )}
        </Right>
      </Holder>
    </>
  );
}
