import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Holder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90%;
  height: 90%;
  color: #000;
  left: 10%;
  top: 10%;
`;

const Pad = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid #1a204d;
  transform: translate(-50%, -50%);
  z-index: 0;
  transition: 0.5s;

  &.next {
    background: rgb(0, 240, 136);
    z-index: 1;
    box-shadow: 0px 0px 30px 10px rgba(0, 240, 136, 0.7);
    border: none;
    cursor: pointer;
  }
`;

export default function MegaPixelPlayable() {
  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const getNewIndex = () => {
    const newIndex = randomIntFromInterval(0, 8);
    setIndex(newIndex);
  };

  const [width, setWidth] = useState(0);
  const [index, setIndex] = useState(randomIntFromInterval(0, 8));

  const init = () => {
    setWidth((document.getElementById("megapixel").offsetWidth - 40) / 4);
  };

  useEffect(() => {
    init();
    window.addEventListener("load", init);
    window.addEventListener("resize", init);
  });

  return (
    <Holder id="megapixel">
      <Pad
        className={index === 0 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 0) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginLeft: -width,
          marginTop: -width,
        }}
      />
      <Pad
        className={index === 1 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 1) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginTop: -width,
        }}
      />
      <Pad
        className={index === 2 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 2) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginTop: -width,
          marginLeft: width,
        }}
      />
      <Pad
        className={index === 3 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 3) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginLeft: -width,
        }}
      />
      <Pad
        className={index === 4 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 4) {
            getNewIndex();
          }
        }}
        style={{ width: width, height: width }}
      />
      <Pad
        className={index === 5 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 5) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginLeft: width,
        }}
      />
      <Pad
        className={index === 6 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 6) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginLeft: -width,
          marginTop: width,
        }}
      />
      <Pad
        className={index === 7 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 7) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginTop: width,
        }}
      />
      <Pad
        className={index === 8 ? "next" : ""}
        onMouseEnter={() => {
          if (index === 8) {
            getNewIndex();
          }
        }}
        style={{
          width: width,
          height: width,
          marginTop: width,
          marginLeft: width,
        }}
      />
    </Holder>
  );
}
