import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../page";

const Holder = styled.div`
    width: 100%;
    padding-bottom: 0px;
    overflow: hidden;

    @media (max-width: 1024px) {
        padding-bottom: 160px;
    }
`;

const Content = styled.div`
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
`;

const Block = styled.div`
    position: relative;
    left: 0px;
    top: 0px;
    padding: 48px 48px 40px 48px;
    width: 652px;
    z-index: 1;

    span {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 56px;
        display: block;
    }

    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 40px */

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const Icon = styled.img`
    position: absolute;
    right: -20px;
    bottom: -20px;
    transform: rotate(20deg);

    @media (max-width: 1024px) {
        display: none;
    }
`;

const List = styled.div`
    margin-top: 48px;
    clear: both;

    span {
        display: flex;
        align-items: center;
        justify-content: start;

        img {
            margin-right: 8px;
        }
    }
`;

const ListItem = styled.div`
    float: left;
    width: fit-content;
    white-space: nowrap;
    padding: 8px 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 100px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

// const Clear = styled.div`
//   clear: both;
// `;

const Game = styled.div`
    display: inline-block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const Action = styled.a`
    color: #000;
    font-family: "Space Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    justify-content: start;
    background: #00f088;
    padding: 0px 32px;
    height: 80px;
    width: 80%;
    position: absolute;
    bottom: -80px;
    left: 0px;
    z-index: 1;

    img {
        margin-right: 16px;
        height: 32px;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const BottomIcon = styled.div`
    height: 80px;
    position: absolute;
    bottom: -160px;
    right: 20%;
    z-index: 2;

    @media (max-width: 1024px) {
        right: 0px;
        svg {
            height: 80px;
            width: 80px;
        }
    }
`;

export default function GameInfo({
    icon,
    color1,
    title,
    content,
    habilidades,
    regras,
    children,
}) {
    const [h, setH] = useState(0);

    const getH = () => {
        const element = document.getElementById(`game_${title}`);
        setH(element.offsetHeight);
    };

    useEffect(() => {
        getH();
        window.addEventListener("resize", getH);
        window.addEventListener("load", getH);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Holder>
            <Page>
                <Content>
                    <Block style={{ background: color1 }} id={`game_${title}`}>
                        <Icon src={icon} alt={title} />
                        <span>{title}</span>
                        {content}
                        <List>
                            <span>
                                <img
                                    src="/img/icon_asterisc.svg"
                                    alt="Habilidades"
                                />
                                Habilidades
                            </span>
                            {habilidades.map((item, i) => {
                                return <ListItem key={i}>{item}</ListItem>;
                            })}
                        </List>
                        <List>
                            <span>
                                <img
                                    src="/img/icon_arrow_right_white.svg"
                                    alt="Habilidades"
                                />
                                Recomendações
                            </span>
                            {regras.map((item, i) => {
                                return <ListItem key={i}>{item}</ListItem>;
                            })}
                        </List>
                        <Action href="https://reservar.arenapixel.com.br">
                            <img src="/img/arrow_header.svg" alt="Reservar" />{" "}
                            Reservar
                        </Action>
                        <BottomIcon>
                            <svg
                                width="160"
                                height="160"
                                viewBox="0 0 182 182"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="182" height="182" fill={color1} />
                                <path
                                    d="M114.74 82.9128H123.397C126.402 82.9128 129.097 81.0667 130.178 78.2705L146.561 36H126.922L110.557 76.9143C109.542 79.4237 106.704 81.461 104.194 81.461H78.3721C75.8628 81.461 73.8254 79.4237 73.8254 76.9143V70.5513C73.8254 66.5364 70.5692 63.2802 66.5543 63.2802H60.1913C57.682 63.2802 55.6446 61.2428 55.6446 58.7335V36.006H36.006V57.4609C36.006 61.4758 39.2622 64.732 43.2771 64.732H49.6401C52.1494 64.732 54.1868 66.7694 54.1868 69.2787V75.6417C54.1868 79.6567 57.443 82.9128 61.4579 82.9128H67.8209C70.3303 82.9128 72.3676 84.9502 72.3676 87.4595V95.0951C72.3676 97.6045 70.3303 99.6418 67.8209 99.6418H59.1637C56.1644 99.6418 53.4698 101.488 52.3825 104.29L36 146.555H55.6386L72.0032 105.64C73.0189 103.131 75.8568 101.094 78.3661 101.094H104.189C106.698 101.094 108.735 103.131 108.735 105.64V112.003C108.735 116.018 111.991 119.274 116.006 119.274H122.369C124.879 119.274 126.916 121.312 126.916 123.821V146.549H146.555V125.094C146.555 121.079 143.298 117.823 139.284 117.823H132.921C130.411 117.823 128.374 115.785 128.374 113.276V106.913C128.374 102.898 125.118 99.6418 121.103 99.6418H114.74C112.23 99.6418 110.193 97.6045 110.193 95.0951V87.4595C110.193 84.9502 112.23 82.9128 114.74 82.9128Z"
                                    fill="white"
                                />
                            </svg>
                        </BottomIcon>
                    </Block>
                    <Game style={{ height: h + 240 }}>{children}</Game>
                </Content>
            </Page>
        </Holder>
    );
}
