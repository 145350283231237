import { Routes, Route } from "react-router-dom";
import React from "react";

import "./styles/main.css";

// import Waiting from "./pages/waiting.js";
import Home from "./pages/home.js";
import Login from "./pages/user/login.js";
import Setup from "./pages/user/setup.js";
import Tickets from "./pages/user/tickets.js";
import TicketDetail from "./pages/user/ticket.js";
import Ranking from "./pages/user/ranking.js";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/home" element={<Home />} /> */}

            <Route path="/user/login" element={<Login />} />
            <Route path="/user/login/:transaction" element={<Login />} />
            <Route path="/user/setup" element={<Setup />} />
            <Route path="/user/tickets" element={<Tickets />} />
            <Route
                path="/user/ticket/:transaction"
                element={<TicketDetail />}
            />

            <Route path="/user/ranking" element={<Ranking />} />

            <Route path="*" element={<Home />} />
        </Routes>
    );
}

export default App;
