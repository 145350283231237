const API_URL = process.env.REACT_APP_API_URL;

export default function Api() {
  const loginWithTransaction = async function (transaction) {
    return fetch(API_URL + `/user/login/${transaction}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //   username,
      //   password,
      // }),
    }).then((data) => {
      return data.json();
    });
  };
  const getUser = async function (token) {
    return fetch(API_URL + `/user/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => {
      return data.json();
    });
  };
  const requestCode = async function (email) {
    return fetch(API_URL + `/user/code/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    }).then((data) => {
      return data.json();
    });
  };
  const validateCode = async function (code) {
    return fetch(API_URL + `/user/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
      }),
    }).then((data) => {
      return data.json();
    });
  };
  const updateProfile = async function (token, surname) {
    return fetch(API_URL + `/user/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        surname,
      }),
    }).then((data) => {
      return data.json();
    });
  };
  const createTeam = async function (token, name, flag) {
    return fetch(API_URL + `/user/teams/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        name,
        flag,
      }),
    }).then((data) => {
      return data.json();
    });
  };
  const updateTeam = async function (token, hash, name, flag) {
    return fetch(API_URL + `/user/teams/${hash}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        name,
        flag,
      }),
    }).then((data) => {
      return data.json();
    });
  };
  const joinTeam = async function (token, hash) {
    return fetch(API_URL + `/user/teams/join/${hash}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => {
      return data.json();
    });
  };
  const getRanking = async function (token) {
    return fetch(API_URL + `/user/ranking/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((data) => {
      return data.json();
    });
  };
  return {
    loginWithTransaction,
    getUser,
    requestCode,
    validateCode,
    updateProfile,
    createTeam,
    updateTeam,
    joinTeam,
    getRanking,
  };
}
