import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Matter from "matter-js";

const Holder = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #000;

    div,
    canvas {
        position: relative;
        z-index: 0;
    }
`;

const Target = styled.img`
    z-index: 1;
    position: absolute;
    right: 0px;
    top: 40%;
    height: 300px;
    pointer-events: none;
`;

export default function DankPlayable() {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const resize = () => {
        setWidth(document.getElementById("holder").offsetWidth);
        setHeight(document.getElementById("holder").offsetHeight);
    };

    const run = () => {
        const element = document.getElementById("dank");
        resize();

        if (width > 300 && height > 300) {
            let Engine = Matter.Engine;
            let Render = Matter.Render;
            let World = Matter.World;
            let Bodies = Matter.Bodies;
            let Mouse = Matter.Mouse;
            let MouseConstraint = Matter.MouseConstraint;

            let engine = Engine.create({
                gravity: {
                    scale: 0.001,
                    x: 0,
                    y: 2,
                },
            });

            let render = Render.create({
                element: element,
                engine: engine,
                options: {
                    width: width,
                    height: height,
                    wireframes: false,
                    background: "#000",
                },
            });

            let circle1 = Bodies.circle(width / 2, 50, 30, {
                restitution: 0.9,
                render: {
                    fillStyle: "#00f088",
                    sprite: {
                        texture: "/img/dank_ball.png",
                    },
                },
            });

            let circle2 = Bodies.circle(width / 2, 50, 30, {
                restitution: 0.9,
                render: {
                    fillStyle: "#00f088",
                    sprite: {
                        texture: "/img/dank_ball.png",
                    },
                },
            });

            let circle3 = Bodies.circle(width / 2, 50, 30, {
                restitution: 0.9,
                render: {
                    fillStyle: "#00f088",
                    sprite: {
                        texture: "/img/dank_ball.png",
                    },
                },
            });

            let circle4 = Bodies.circle(width / 2, 50, 30, {
                restitution: 0.9,
                render: {
                    fillStyle: "#00f088",
                    sprite: {
                        texture: "/img/dank_ball.png",
                    },
                },
            });

            let circle5 = Bodies.circle(width / 2, 50, 30, {
                restitution: 0.9,
                render: {
                    fillStyle: "#00f088",
                    sprite: {
                        texture: "/img/dank_ball.png",
                    },
                },
            });

            let circle6 = Bodies.circle(width / 2, 50, 30, {
                restitution: 0.9,
                render: {
                    fillStyle: "#00f088",
                    sprite: {
                        texture: "/img/dank_ball.png",
                    },
                },
            });

            let ground = Bodies.rectangle(width / 2, height - 20, width, 1, {
                isStatic: true,
                render: {
                    fillStyle: "#000",
                    strokeStyle: "transparent",
                    lineWidth: 0,
                },
            });

            let wallLeft = Bodies.rectangle(0, height / 2, 1, height, {
                isStatic: true,
                render: {
                    fillStyle: "#000",
                    strokeStyle: "transparent",
                    lineWidth: 0,
                },
            });

            let wallRight = Bodies.rectangle(
                width - 30,
                height / 2,
                1,
                height,
                {
                    isStatic: true,
                    render: {
                        fillStyle: "#000",
                        strokeStyle: "transparent",
                        lineWidth: 0,
                    },
                }
            );

            let roof = Bodies.rectangle(width / 2, 0, width, 1, {
                isStatic: true,
                render: {
                    fillStyle: "#000",
                    strokeStyle: "transparent",
                    lineWidth: 0,
                },
            });

            World.add(engine.world, [
                circle1,
                circle2,
                circle3,
                circle4,
                circle5,
                circle6,
                ground,
                wallLeft,
                wallRight,
                roof,
            ]);

            let mouse = Mouse.create(render.canvas);
            let mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
            });

            mouseConstraint.mouse.element.removeEventListener(
                "mousewheel",
                mouseConstraint.mouse.mousewheel
            );
            mouseConstraint.mouse.element.removeEventListener(
                "DOMMouseScroll",
                mouseConstraint.mouse.mousewheel
            );

            Matter.World.add(engine.world, mouseConstraint);
            render.mouse = mouse;

            function start() {
                Matter.Runner.run(engine);
                Render.run(render);
            }

            start();
        }
    };

    useEffect(() => {
        run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    return (
        <Holder id="holder">
            <Target src="/img/dank_target.svg" />
            <div
                id="dank"
                style={{
                    width: width,
                    height: height,
                }}
            />
        </Holder>
    );
}
