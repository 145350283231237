import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../page";

const Holder = styled.div`
    background: #fff;
    position: relative;

    .page {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);

        @media (max-width: 1024px) {
            position: relative;
            bottom: 0px;
            left: auto;
            transform: translate(0);
        }
    }

    @media (max-width: 1024px) {
        height: auto !important;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const Bar = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 90%;
    background: #ff4824;
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 40px */

    img {
        margin-right: 48px;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const Spacer = styled.div`
    width: 100%;
    height: 180px;

    @media (max-width: 1024px) {
        height: 120px;
    }
`;

const Blocks = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 1024px) {
        display: block;
    }
`;

const Block = styled.div`
    width: 100%;
    height: 480px;
`;

const Step1 = styled.div`
    width: 100%;
    height: 100%;
    background: #00f088;
    position: relative;
`;
const Step2 = styled.div`
    width: 100%;
    height: 100%;
    background: #ebdf35;
    position: relative;
`;
const Step3 = styled.div`
    width: 100%;
    height: 100%;
    background: #f471fd;
    position: relative;
`;

const Number = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 120px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 120px */
    letter-spacing: -6px;
    background: #fff;
    width: 50%;
    height: 50%;
    position: absolute;
`;

const Icon = styled.img`
    position: absolute;
`;

const Text = styled.div`
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 40px */
    padding: 48px;
    position: absolute;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: start;
`;

export default function Steps({ width }) {
    const [height, setHeight] = useState(window.innerHeight - 80);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setHeight(window.innerHeight);
        });
    }, []);

    return (
        <Holder style={{ height: height }}>
            <Header>
                <Bar>
                    <img src="/img/icon_works.svg" alt="Como funciona?" />{" "}
                    Circuito Pixel: como funciona?
                </Bar>
            </Header>
            <Spacer />
            <Page>
                <Blocks>
                    <Block>
                        <Step1>
                            <Number style={{ right: 0, bottom: 0 }}>01</Number>
                            <Icon
                                src="/img/icon_step_1.svg"
                                style={{
                                    left: width < 1024 ? 16 : 48,
                                    bottom: width < 1024 ? 16 : 48,
                                }}
                            />
                            <Text style={{ left: 0, top: 0 }}>
                                Monte seu time
                            </Text>
                        </Step1>
                    </Block>
                    <Block>
                        <Step2>
                            <Number style={{ left: 0, top: 0 }}>02</Number>
                            <Icon
                                src="/img/icon_step_2.svg"
                                style={{
                                    right: width < 1024 ? 16 : 48,
                                    top: width < 1024 ? 16 : 48,
                                }}
                            />
                            <Text
                                style={{
                                    left: 0,
                                    bottom: 0,
                                    alignItems: "end",
                                }}
                            >
                                Agende sua reserva
                            </Text>
                        </Step2>
                    </Block>
                    <Block>
                        <Step3>
                            <Number style={{ left: 0, bottom: 0 }}>03</Number>
                            <Icon
                                src="/img/icon_step_3.svg"
                                style={{
                                    right: width < 1024 ? 16 : 48,
                                    bottom: width < 1024 ? 16 : 48,
                                }}
                            />
                            <Text style={{ left: 0, top: 0 }}>
                                Explore as salas de jogos da Arena Pixel
                            </Text>
                        </Step3>
                    </Block>
                </Blocks>
            </Page>
        </Holder>
    );
}
