import React from "react";
import styled from "styled-components";

import GameHeader from "../blocks/game_header";
import GameInfo from "../blocks/game_info";
import MegaPixelPlayable from "./playable/megapixel";

const Holder = styled.div``;

export default function MegaPixel() {
    return (
        <Holder>
            <GameHeader
                id="games"
                title="MEGAPIXEL"
                video="/videos/megapixel.mp4"
                rotation={-3}
                bottom={160}
                icon="/img/game_logo_megapixel.svg"
                color1="#344BFF"
                color2="#00F088"
            />
            <GameInfo
                icon="/img/icon_1.svg"
                color1="#344BFF"
                title={"Megapixel"}
                content={
                    "Como uma pista de dança, ou como um campo minado, pise ou evite os quadrados interativos espalhados pela sala."
                }
                habilidades={["Velocidade", "Agilidade", "Comunicação"]}
                regras={[
                    "Time de 2 a 6 pessoas",
                    "Estar saltitante",
                    "Todas as idades",
                ]}
            >
                <MegaPixelPlayable />
            </GameInfo>
        </Holder>
    );
}
