import React from "react";
import styled from "styled-components";

import GameHeader from "../blocks/game_header";
import GameInfo from "../blocks/game_info";
import QuestPlayable from "./playable/quest";

const Holder = styled.div``;

export default function Quest() {
    return (
        <Holder>
            <GameHeader
                title="QUEST"
                video="/videos/quest.mp4"
                rotation={-3}
                bottom={160}
                icon="/img/game_logo_quest.svg"
                color1="#FF66FF"
                color2="#00F088"
            />
            <GameInfo
                icon="/img/icon_4.svg"
                color1="#FF66FF"
                title={"Quest"}
                content={
                    "Desafie seus conhecimentos gerais e encontre as respostas em uma sala cheia de letras, números e formas."
                }
                habilidades={["Raciocinio", "Velocidade", "Comunicação"]}
                regras={[
                    "Time de 2 a 6 pessoas",
                    "Separe o Aurélio",
                    "Todas as idades",
                ]}
            >
                <QuestPlayable />
            </GameInfo>
        </Holder>
    );
}
