import React from "react";
import styled from "styled-components";

import GameHeader from "../blocks/game_header";
import GameInfo from "../blocks/game_info";
import LaserPlayable from "./playable/laser";

const Holder = styled.div``;

export default function Laser() {
    return (
        <Holder>
            <GameHeader
                title="LASER"
                video="/videos/laser.mp4"
                rotation={3}
                bottom={0}
                icon="/img/game_logo_laser.svg"
                color1="#FF4824"
                color2="#00F088"
            />
            <GameInfo
                icon="/img/icon_3.svg"
                color1="#FF4824"
                title={"Laser"}
                content={
                    "Sempre quis estrelar um filme do 007 ou Matrix? Junto com o seu time, desvie das faixas de laser para alcançar o seu objetivo."
                }
                habilidades={["Velocidade", "Agilidade", "Precisão"]}
                regras={[
                    "Time de 2 a 6 pessoas",
                    "Não ative o alarme",
                    "Todas as idades",
                ]}
            >
                <LaserPlayable />
            </GameInfo>
        </Holder>
    );
}
