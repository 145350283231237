import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  display: flex;
  align-items: center;

  padding: 24px 48px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;

  svg {
    margin-right: 16px;
  }

  &:hover {
    text-decoration-line: underline;
  }

  &.start {
    padding: 24px 0px;
  }

  @media (max-width: 1024px) {
    padding: 16px 24px;
    &.start {
      padding: 16px 0px;
    }
    svg {
      height: 24px;
    }
  }
`;

export default function Button({
  color,
  background,
  text,
  width,
  skip,
  start,
  callback,
}) {
  return (
    <Holder
      style={{
        width: width ? width : "100%",
        background: background,
        color: color,
        justifyContent: start ? "start" : skip ? "end" : "start",
        fontSize: skip ? 16 : 24,
      }}
      className={start ? "start" : null}
      onClick={callback ? callback : null}
    >
      {skip ? (
        <>{text}</>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="32"
            viewBox="0 0 31 32"
            fill="none"
          >
            <path
              d="M26.9253 13.7136C26.445 13.7136 26.0574 13.304 26.0574 12.8L26.0574 10.5136C26.0574 9.7568 25.4737 9.1424 24.7547 9.1424L22.5826 9.1424C22.1023 9.1424 21.7147 8.7328 21.7147 8.2288L21.7147 5.9424C21.7147 5.1856 21.131 4.5712 20.4121 4.5712L18.24 4.5712C17.7597 4.5712 17.3721 4.1616 17.3721 3.6576L17.3721 1.3712C17.3721 0.6144 16.7884 -6.78171e-10 16.0694 -6.49129e-10L8.6868 -3.50905e-10L8.6868 4.5712L16.5042 4.5712C16.9845 4.5712 17.3721 4.9808 17.3721 5.4848L17.3721 7.7712C17.3721 8.528 17.9558 9.1424 18.6747 9.1424L20.8468 9.1424C21.3271 9.1424 21.7147 9.552 21.7147 10.056L21.7147 12.7984C21.7147 13.304 21.3256 13.712 20.8468 13.712L4.99894e-10 13.712L6.66545e-10 18.2832L20.8453 18.2832C21.3256 18.2832 21.7132 18.6928 21.7132 19.1968L21.7132 21.9392C21.7132 22.4448 21.3241 22.8528 20.8453 22.8528L18.6732 22.8528C17.9542 22.8528 17.3706 23.4672 17.3706 24.224L17.3706 26.5104C17.3706 27.016 16.9814 27.424 16.5026 27.424L8.68528 27.424L8.68528 31.9952L16.0679 31.9952C16.7869 31.9952 17.3706 31.3808 17.3706 30.624L17.3706 28.3376C17.3706 27.832 17.7597 27.424 18.2385 27.424L20.4106 27.424C21.1295 27.424 21.7132 26.8096 21.7132 26.0528L21.7132 23.7664C21.7132 23.2608 22.1023 22.8528 22.5811 22.8528L24.7532 22.8528C25.4722 22.8528 26.0558 22.2384 26.0558 21.4816L26.0558 19.1952C26.0558 18.6896 26.445 18.2816 26.9238 18.2816L30.3985 18.2816L30.3985 13.7104L26.9238 13.7104L26.9253 13.7136ZM22.5826 18.2848C22.1023 18.2848 21.7147 17.8752 21.7147 17.3712L21.7147 14.6288C21.7147 14.1232 22.1038 13.7152 22.5826 13.7152L25.1879 13.7152C25.6682 13.7152 26.0558 14.1248 26.0558 14.6288L26.0558 17.3712C26.0558 17.8768 25.6667 18.2848 25.1879 18.2848L22.5826 18.2848Z"
              fill={color ? color : "#fff"}
            />
          </svg>
          {text}
        </>
      )}
    </Holder>
  );
}
