import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export default function Page({ background, overflow, children }) {
  return (
    <Holder
      style={{
        background: background ? background : "",
        overflow: overflow ? "hidden" : "visible",
      }}
      className="page"
    >
      {children}
    </Holder>
  );
}
