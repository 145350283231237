import React from "react";
import styled from "styled-components";
import Helper from "../../libs/helper";
import Text from "./text";
import Button from "./button";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";

const Holder = styled.div`
  width: 100%;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
`;

const Flag = styled.div`
  display: flex;
  background: #fff;
  align-items: start;
  justify-content: space-between;
`;

const FlagName = styled.div`
  width: 100%;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: 188px;
  flex: 1;
  word-break: break-all;

  @media (max-width: 1024px) {
    height: 144px;
    padding: 16px;
  }
`;

const Players = styled.div`
  border-radius: 29px;
  background: #000;
  padding: 8px 16px;
  color: #fff;
  font-size: 16px;
  @media (max-width: 1024px) {
    padding: 0px 16px;
  }
`;

const FlagImage = styled.img`
  width: 188px;
  height: 188px;

  @media (max-width: 1024px) {
    height: 144px;
    width: 144px;
  }
`;

const DateHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Date = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebdf35;

  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;

  @media (max-width: 1024px) {
    height: 72px;
    font-size: 24px;
  }
`;
const Time = styled.div`
  width: 188px;
  min-width: 188px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f471fd;
  text-transform: capitalize;

  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;

  @media (max-width: 1024px) {
    width: 144px;
    min-width: 144px;
    height: 72px;
    font-size: 24px;
  }
`;

const Label = styled.div`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-top: 8px;

  a {
    text-decoration: underline;
    color: #fff;
  }
`;

const Blue = styled.div`
  background: #344bff;
  color: var(--Cores-Branco, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 24px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 16px;
  }

  span {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #fff;
  }
`;

const Star = styled.img`
  position: absolute;
  right: -40px;
  top: -40px;
  transform: rotate(24deg);
`;

const BarcodeBox = styled.div`
  padding: 16px;
  background: #fff;
  position: relative;
  border-bottom: 4px dashed #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Barcode = styled.div`
  width: 135px;
  height: 135px;
`;

const Data = styled.div`
  width: 100%;
  padding: 0px 16px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;

const DataTitle = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
`;
const DataValue = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  width: 100%;
  text-align: center;
`;

export default function Ticket({ user, showBarcode, barcodeIndex }) {
  const helper = Helper();
  const navigate = useNavigate();

  return (
    <Holder>
      {user && user.tickets.length > 0 && user.tickets[0].team && (
        <>
          {!showBarcode && <Text color={"#fff"}>Próximas Datas</Text>}
          <Flag>
            <FlagName>
              <Players>
                {user.tickets[0].extra_barcodes.length + 1 === 0
                  ? "Nenhum Player"
                  : user.tickets[0].extra_barcodes.length + 1 === 1
                  ? "1 Player"
                  : `${user.tickets[0].extra_barcodes.length + 1} Players`}
              </Players>
              {user.tickets[0].team.name}
            </FlagName>
            <FlagImage
              src={`/img/flags/flag${user.tickets[0].team.flag}.svg`}
              alt="Bandeira"
            />
          </Flag>
          <DateHolder>
            <Date>
              {
                helper.formatDate(user.tickets[0].initial_validity)
                  .formattedDate
              }
            </Date>
            <Time>
              {helper.formatDate(user.tickets[0].initial_validity).formattedDay}{" "}
              {
                helper.formatDate(user.tickets[0].initial_validity)
                  .formattedTime
              }
            </Time>
          </DateHolder>
          {showBarcode && (
            <BarcodeBox>
              <Barcode>
                <QRCode
                  value={
                    barcodeIndex === 0
                      ? user.tickets[0].barcode
                      : user.tickets[0].extra_barcodes[barcodeIndex - 1]
                  }
                  size={135}
                />
              </Barcode>
              <Data>
                <DataTitle>Valor</DataTitle>
                <DataValue>
                  {helper.formatToBRL(user.tickets[0].value)}
                </DataValue>
              </Data>
            </BarcodeBox>
          )}
          {!showBarcode && (
            <Button
              text={"Mais informações"}
              color={"#000"}
              background={"#00F088"}
              callback={() => {
                navigate(`/user/ticket/${user.tickets[0].id}`);
              }}
            />
          )}
          {!showBarcode && (
            <Label>
              Tem algo de errado com a reserva? Fale conosco através do{" "}
              <a
                href="mailto:contato@arenapixel.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Suporte
              </a>
              .
            </Label>
          )}
        </>
      )}
      {user && user.tickets.length === 0 && (
        <>
          <Blue>
            <Star src="/img/user/star_tickets.svg" />
            <span>Boas vindas!</span>Parece que você não tem nenhuma reserva
            agendada, que tal marcar sua visita?
          </Blue>
          <Button
            text={"Reservar"}
            color={"#000"}
            background={"#00F088"}
            callback={() => {
              window.open("https://reservar.arenapixel.com.br");
            }}
          />
          <Label>
            Não encontra sua reserva? Fale conosco através do nosso{" "}
            <a
              href="mailto:contato@arenapixel.com.br"
              target="_blank"
              rel="noreferrer"
            >
              Suporte
            </a>
            .
          </Label>
        </>
      )}
      {user && user.tickets.length > 0 && !user.tickets[0].team && (
        <>
          <Blue>
            <Star src="/img/user/star_tickets.svg" />
            <span>Boas vindas!</span>Você ainda não criou o time da sua próxima
            reserva! Que tal fazer isso agora?
          </Blue>
          <Button
            text={"Criar time"}
            color={"#000"}
            background={"#00F088"}
            callback={() => {
              navigate("/user/setup/");
            }}
          />
        </>
      )}
    </Holder>
  );
}
