import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  svg {
    margin-right: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 32px;

    svg {
      height: 24px;
    }
  }
`;

export default function Title({ children, color }) {
  return <Holder style={{ color: color ? color : "#000" }}>{children}</Holder>;
}
