import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Wrapper from "../../components/user/wrapper";
import Title from "../../components/user/title";
import Ticket from "../../components/user/ticket";
import SubMenu from "../../components/user/submenu";
import Helper from "../../libs/helper";
import QRCode from "qrcode.react";

const Holder = styled.div`
  padding: 48px;
  height: 100%;
  position: relative;
  flex: 1;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    padding: 16px;
    margin-bottom: 80px;
  }
`;

const Hide = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const PaymentBox = styled.div`
  @media (max-width: 1024px) {
    margin-top: 56px;
  }
`;

const Data = styled.div`
  background: #fff;
  padding: 16px;
  border-top: 4px dashed #000;
  margin-top: 16px;
`;
const Total = styled.div`
  padding: 16px;
  background-color: #00f088;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TotalLabel = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 30px */
`;
const TotalValue = styled.div`
  color: var(--Cores-Preto, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 37.5px */
`;

const Barcodes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 48px;
  width: 100%;
  margin-bottom: 40px;
`;

const Barcode = styled.div`
  width: 200px;
  height: 206px;
  padding: 16px 16px 0px 16px;
  background: #fff;
  border-bottom: 4px dashed #000;
`;

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 390px;
`;

const TicketHolder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90%;
  transition: 1s;
  z-index: 10;
  opacity: 1;

  &.index-1,
  &.index-2,
  &.index-3,
  &.index-4,
  &.index-5,
  &.index-6 {
    left: -60px;
    transform: scale(1.01);
    opacity: 0;
    z-index: 11;
  }

  &.index1 {
    left: 20px;
    transform: scale(0.99);
    z-index: 9;
  }

  &.index2 {
    left: 40px;
    transform: scale(0.98);
    z-index: 8;
  }

  &.index3 {
    left: 60px;
    transform: scale(0.97);
    z-index: 7;
  }

  &.index4 {
    left: 80px;
    transform: scale(0.96);
    z-index: 6;
  }

  &.index5 {
    left: 100px;
    transform: scale(0.95);
    z-index: 5;
  }

  &.index6 {
    left: 120px;
    transform: scale(0.94);
    z-index: 4;
  }
`;

const Dots = styled.div`
  margin-top: 24px;
  height: 8px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: #fff;
  margin-right: 8px;
  float: left;
  cursor: pointer;
  &:hover,
  &.active {
    background: #00f088;
  }
`;

export default function TicketDetail() {
  const [user, setUser] = useState();
  const [active, setActive] = useState(0);

  const helper = Helper();

  const [touchstartX, setTouchstartX] = useState(0);
  const [touchendX, setTouchendX] = useState(0);

  function handleSwipe() {
    if (touchendX < touchstartX) {
      onSwipeLeft();
    }
    if (touchendX > touchstartX) {
      onSwipeRight();
    }
  }

  function onSwipeLeft() {
    let _active = active + 1;
    if (user && _active >= user.tickets[0].extra_barcodes.length) {
      _active = user.tickets[0].extra_barcodes.length;
    }
    setActive(_active);
  }

  function onSwipeRight() {
    let _active = active - 1;
    if (_active <= 0) {
      _active = 0;
    }
    setActive(_active);
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      backgroundLeft={"#000"}
      backgroundRight={"#111"}
      footerType={0}
      login={true}
      loginCallback={setUser}
    >
      <Holder>
        <Title color={"#fff"}>Sua Reserva</Title>
        <br />
        <Slider
          id="slider"
          onTouchStart={(e) => {
            setTouchstartX(e.changedTouches[0].screenX);
          }}
          onTouchEnd={(e) => {
            setTouchendX(e.changedTouches[0].screenX);
            handleSwipe();
          }}
        >
          <TicketHolder key={0} className={`index${0 - active}`}>
            <Ticket user={user} showBarcode barcodeIndex={0} />
          </TicketHolder>
          {user &&
            user.tickets[0].extra_barcodes.map((barcode, index) => {
              return (
                <TicketHolder
                  key={index + 1}
                  className={`index${index + 1 - active}`}
                >
                  <Ticket user={user} showBarcode barcodeIndex={index + 1} />
                </TicketHolder>
              );
            })}
        </Slider>
        <Hide>
          <Dots>
            <Dot
              key={0}
              className={active === 0 ? "active" : null}
              onClick={() => {
                setActive(0);
              }}
            ></Dot>
            {user &&
              user.tickets[0].extra_barcodes.map((barcode, index) => {
                return (
                  <Dot
                    className={active === index + 1 ? "active" : null}
                    key={index + 1}
                    onClick={() => {
                      setActive(index + 1);
                    }}
                  ></Dot>
                );
              })}
          </Dots>
          <PaymentBox>
            <Title color={"#fff"}>Pagamento</Title>
            <Data>
              <strong>
                {user && user.tickets[0].extra_barcodes.length + 1} Ingresso(s)
              </strong>
            </Data>
            <Total>
              <TotalLabel>Total</TotalLabel>
              <TotalValue>
                {user && helper.formatToBRL(user.tickets[0].value)}
              </TotalValue>
            </Total>
          </PaymentBox>
        </Hide>
        <Bottom>
          <SubMenu />
        </Bottom>
      </Holder>
      <Holder>
        <PaymentBox>
          <Barcodes>
            {user && (
              <Barcode>
                <QRCode value={user.tickets[0].barcode} size={167} />
              </Barcode>
            )}
            {user &&
              user.tickets[0].extra_barcodes.map((barcode, index) => {
                return (
                  <Barcode>
                    <QRCode key={index} value={barcode} size={167} />
                  </Barcode>
                );
              })}
          </Barcodes>
          <Data>
            <strong>
              {user && user.tickets[0].extra_barcodes.length + 1} Ingresso(s)
            </strong>
          </Data>
          <Total>
            <TotalLabel>Total</TotalLabel>
            <TotalValue>
              {user && helper.formatToBRL(user.tickets[0].value)}
            </TotalValue>
          </Total>
        </PaymentBox>
      </Holder>
    </Wrapper>
  );
}
