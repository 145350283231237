import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Holder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  color: #000;
`;

const DottedHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 32px;
  position: relative;
  z-index: 0;
  top: 0px;
  flex-wrap: wrap;
`;

const TypedHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 32px;
  position: absolute;
  z-index: 1;
  top: 0px;
  flex-wrap: wrap;
  color: #fff;
`;

const Dotted = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px dashed rgba(255, 255, 255, 0.5);
  margin: 0px 24px;
  margin: 24px 24px;
`;

const LetterHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 32px;
  flex-wrap: wrap;
`;

const Letter = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;
  margin: 24px 24px;
  opacity: 1;
  transition: 0.5s;

  &.right {
    background: #2eff00 !important;
  }

  &.wrong {
    background: #ff0000 !important;
  }
`;

function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

export default function QuestPlayable() {
  let colors = ["#00F088", "#344BFF", "#FF4824", "#FF66FF", "#FF4824"];

  const [word, setWord] = useState();
  const [letters, setLetters] = useState([]);

  const [right, setRight] = useState(false);
  const [wrong, setWrong] = useState(false);

  const startGame = () => {
    shuffle(colors);

    const words = [
      "sagaz",
      "negro",
      "mexer",
      "termo",
      "nobre",
      "senso",
      "algoz",
      "afeto",
      "plena",
      "fazer",
      "sutil",
      "vigor",
      "assim",
      "audaz",
      "sanar",
      "fosse",
      "cerne",
      "ideia",
      "inato",
      "poder",
      "moral",
      "desde",
      "sobre",
      "justo",
      "muito",
      "torpe",
      "honra",
      "sonho",
      "etnia",
      "anexo",
      "amigo",
      "tange",
      "lapso",
      "expor",
      "haver",
      "casal",
      "dengo",
    ];

    const _word = words[Math.floor(Math.random() * words.length)].toUpperCase();
    setWord(_word);
    let _letters = _word.split("");
    shuffle(_letters);
    setLetters(_letters);
  };

  const [typed, setTyped] = useState([]);

  const sendLetter = (letter) => {
    let _typed = [...typed];
    _typed.push(letter);
    setTyped(_typed);
  };

  useEffect(() => {
    if (typed.length === 5) {
      if (typed.join("") === word) {
        setRight(true);
      } else {
        setWrong(true);
      }

      for (let index = 0; index < 5; index++) {
        document.getElementById(`letter_${index}`).style.opacity = 1;
      }

      setTimeout(() => {
        setRight(false);
        setWrong(false);
        setTyped([]);
        startGame();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typed]);

  useEffect(() => {
    startGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAlpha = (index) => {
    document.getElementById(`letter_${index}`).style.opacity = 0.3;
  };

  return (
    <Holder id="quest">
      <DottedHolder>
        <Dotted />
        <Dotted />
        <Dotted />
        <Dotted />
        <Dotted />
      </DottedHolder>
      <TypedHolder>
        {typed.map((letter, i) => {
          return (
            <Letter
              className={`${right ? "right" : ""} ${wrong ? "wrong" : ""}`}
              key={i}
              style={{ backgroundColor: colors[i] }}
            >
              {letter}
            </Letter>
          );
        })}
      </TypedHolder>
      <LetterHolder>
        {letters.map((letter, i) => {
          return (
            <Letter
              className={`${right ? "right" : ""} ${wrong ? "wrong" : ""}`}
              key={i}
              id={`letter_${i}`}
              onClick={() => {
                sendLetter(letter);
                setAlpha(i);
              }}
              style={{ backgroundColor: colors[i] }}
            >
              {letter}
            </Letter>
          );
        })}
      </LetterHolder>
    </Holder>
  );
}
