import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Holder = styled.div`
    position: relative;
    left: 0px;
    top: 0px;
    height: 80px;
    width: 100%;
    background: #000;
    padding: 20px 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1024px) {
        padding: 20px 16px;
    }

    img {
        cursor: pointer;
    }
`;

export default function Header({ user }) {
    const navigate = useNavigate();

    return (
        <Holder>
            <img
                src="/img/waiting/mini_logo.svg"
                alt="Logo"
                height={40}
                onClick={() => {
                    navigate("/");
                }}
            />
            <img
                src="/img/user/user.svg"
                alt="User"
                height={40}
                onClick={() => {
                    navigate("/user/tickets/");
                }}
            />
        </Holder>
    );
}
