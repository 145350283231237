import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  position: absolute;
  left: 0px;
  top: -5px;
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;

  div,
  canvas {
    position: relative;
    z-index: 0;
  }

  span:nth-child(1) {
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 0s;
    animation-duration: 1s;
  }
  span:nth-child(2) {
    top: 0;
    right: 80px;
    left: initial;
    animation-delay: 0.2s;
    animation-duration: 3s;
  }
  span:nth-child(3) {
    top: 80;
    right: 0px;
    left: initial;
    animation-delay: 0.4s;
    animation-duration: 2s;
  }
  span:nth-child(4) {
    top: 0;
    right: 180px;
    left: initial;
    animation-delay: 0.6s;
    animation-duration: 1.5s;
  }
  span:nth-child(5) {
    top: 0;
    right: 400px;
    left: initial;
    animation-delay: 0.8s;
    animation-duration: 2.5s;
  }
  span:nth-child(6) {
    top: 0;
    right: 600px;
    left: initial;
    animation-delay: 1s;
    animation-duration: 3s;
  }
  span:nth-child(7) {
    top: 300px;
    right: 0px;
    left: initial;
    animation-delay: 1.2s;
    animation-duration: 1.75s;
  }
  span:nth-child(8) {
    top: 0px;
    right: 700px;
    left: initial;
    animation-delay: 1.4s;
    animation-duration: 1.25s;
  }
  span:nth-child(9) {
    top: 0px;
    right: 1000px;
    left: initial;
    animation-delay: 0.75s;
    animation-duration: 2.25s;
  }
  span:nth-child(9) {
    top: 0px;
    right: 450px;
    left: initial;
    animation-delay: 2.75s;
    animation-duration: 2.75s;
  }
`;

const LaserBeam = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #800;
  border-radius: 50%;
  animation: animateLaser 3s linear infinite;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 500px;
    height: 8px;
    background: linear-gradient(90deg, #800, #800);
    image-rendering: pixelated;
    transition: height 0.5s;
    border-radius: 20px;
  }

  &:hover {
    &::before {
      height: 10px;
      background: linear-gradient(90deg, #ff4824, #ff4824);
      box-shadow: 0 0 0 10px rgba(255, 72, 36, 0.1),
        0 0 0 30px rgba(255, 72, 36, 0.1), 0 0 30px rgba(255, 72, 36, 0.1);
    }
  }
`;

export default function LaserPlayable() {
  return (
    <Holder id="laser_holder">
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
      <LaserBeam />
    </Holder>
  );
}
