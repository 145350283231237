import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Holder = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;

    video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: transparent;
        z-index: 0;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
`;

const Marquee = styled.div`
    position: absolute;
    background: #000;
    left: 50%;
    width: 200%;
    height: 210px;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 1024px) {
        width: 1200%;
    }
`;

const Track = styled.div`
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: gameMarquee 80s linear infinite;
    height: 210px;
`;

const Loop = styled.div`
    color: #fff;
    font-size: 144px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%; /* 129.6px */
    letter-spacing: -4.32px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 210px;

    span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 210px;
    }

    img {
        margin: 0px 80px;
    }
`;

export default function GameHeader({
    id,
    title,
    video,
    bottom,
    rotation,
    icon,
    color1,
    color2,
}) {
    const [height, setHeight] = useState(window.innerHeight - 80);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setHeight(window.innerHeight);
        });
    }, []);

    return (
        <Holder style={{ height: height }} id={id}>
            <Content>
                <video autoPlay playsInline muted loop>
                    <source src={video} type="video/mp4" />
                </video>
                <Marquee
                    style={{
                        transform: `rotate(${rotation}deg) translate(-50%, 0)`,
                        bottom: bottom,
                    }}
                >
                    <Track>
                        <Loop>
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16, 17, 18, 19, 20,
                            ].map((i) => {
                                return (
                                    <span key={i}>
                                        {title} <img src={icon} alt={title} />
                                    </span>
                                );
                            })}
                        </Loop>
                    </Track>
                </Marquee>
            </Content>
        </Holder>
    );
}
