import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #efefef;
  padding: 24px 16px;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;

  color: var(--Cores-Preto, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 22.5px */

  &.active {
    text-decoration-line: underline;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const ItemDot = styled.div`
  background: #fff;
  width: 10px;
  height: 10px;
  margin-bottom: 8px;

  &.active {
    background-color: #00f088;
  }
`;

export default function Steps({ step }) {
  return (
    <Holder>
      <Item className={step >= 2 && "active"}>
        <ItemDot className={step >= 2 && "active"} />
        Apelido
      </Item>
      <Item className={step >= 3 && "active"}>
        <ItemDot className={step >= 3 && "active"} />
        Time
      </Item>
      <Item className={step >= 4 && "active"}>
        <ItemDot className={step >= 4 && "active"} />
        Bandeira
      </Item>
      <Item className={step >= 5 && "active"}>
        <ItemDot className={step >= 5 && "active"} />
        Conclusão
      </Item>
    </Holder>
  );
}
