import React, { useState, useRef } from "react";
import styled from "styled-components";

const Holder = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
`;

const Input = styled.input`
  width: 16%;
  height: 80px;
  padding: 24px;
  text-align: center;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  border: none;
  margin-top: 48px;

  @media (max-width: 1024px) {
    height: 58px;
    font-size: 16px;
    padding: 16px;
  }
`;

const ErrorMessage = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  margin-top: 16px;
`;

export default function TwoFactor({ callback, error, errorColor }) {
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const colors = [
    "#F471FD",
    "#EBDF35",
    "#FF4824",
    "#00F088",
    "#344BFF",
    "#EBDF35",
  ];
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (/\D/.test(newValue)) {
      return;
    }

    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);

    if (newValue !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (index === 5 && newValue !== "") {
      callback(newValues.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && values[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(pastedData)) {
      const newValues = pastedData.split("");
      setValues(newValues);
      callback(pastedData);
    }
  };

  return (
    <>
      <Holder>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {values.map((value, index) => (
            <Input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
              style={{
                backgroundColor: error
                  ? "#FF4824"
                  : value
                  ? colors[index]
                  : "#fff",
              }}
            />
          ))}
        </div>
      </Holder>
      {error ? (
        <ErrorMessage style={{ color: errorColor ? errorColor : "#FF4824" }}>
          {error}
        </ErrorMessage>
      ) : null}
    </>
  );
}
