import React from "react";
import styled from "styled-components";
import Title from "./title";

const Wrapper = styled.div`
  padding: 40px;
  width: 100%;
  margin-top: 48px;

  @media (max-width: 1024px) {
    padding: 0px;
    margin-bottom: 72px;
  }
`;

const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 24px;
  width: 100%;
  margin-top: 48px;

  @media (max-width: 1024px) {
  }
`;

const Circuit = styled.div`
  width: 326px;
  max-width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

const Flag = styled.img`
  width: 70px;
  height: 70px;
  margin-right: 24px;
`;

const Data = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

const TeamName = styled.div`
  color: var(--Cores-Branco, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`;

const Players = styled.div`
  color: var(--Cores-Branco, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

const Points = styled.div`
  color: var(--Cores-Branco, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

export default function Circuits({ circuits }) {
  return (
    <Wrapper>
      <Title color={"#fff"}>Circuitos</Title>
      <Holder>
        {circuits.map((circuit, index) => {
          return (
            <Circuit key={index}>
              <Flag src={`/img/flags/flag${circuit.team.flag}.svg`} />
              <Data>
                <TeamName>{circuit.team.name}</TeamName>
                <Players>
                  <strong>{circuit.players}</strong> Player(s)
                </Players>
                <Points>
                  <strong>⚑ {circuit.total}</strong> pts
                </Points>
              </Data>
            </Circuit>
          );
        })}
      </Holder>
    </Wrapper>
  );
}
