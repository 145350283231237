import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Page from "../page";

const Holder = styled.div`
    width: 100%;
`;

const Content = styled.div`
    position: relative;
    background: #111;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 120px 160px 344px 160px;

    img {
        margin-bottom: -6px;
        margin-right: 16px;
    }

    @media (max-width: 1024px) {
        padding: 48px 16px 248px 16px;
        font-size: 28px;
        img {
            margin-bottom: 0px;
        }
        span {
            display: block;
        }
    }
`;

const LeftBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 280px;
    left: 0px;
    bottom: 0px;
    background-image: url("/img/about_bg_left.svg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;

    @media (max-width: 1024px) {
        background-image: url("/img/about_bg_left_mobile.svg");
        height: 200px;
    }
`;
const RightBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 280px;
    right: 0px;
    bottom: 0px;
    background-image: url("/img/about_bg_right.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;

    @media (max-width: 1024px) {
        background-image: url("/img/about_bg_right_mobile.svg");
        height: 200px;
    }
`;

export default function IntroText({
    tag,
    text,
    width,
    leftBackground,
    rightBackground,
    id,
}) {
    const [height, setHeight] = useState(window.innerHeight - 80);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setHeight(window.innerHeight);
        });
    }, []);

    return (
        <Holder id={id} style={{ height: height }}>
            <Content>
                <Page>
                    <Text
                        style={{
                            paddingBottom: !leftBackground
                                ? width < 1024
                                    ? 48
                                    : 96
                                : width < 1024
                                ? 248
                                : 344,
                        }}
                    >
                        <img src={tag} alt="About" />
                        <span>{text}</span>
                    </Text>
                </Page>
                <LeftBackground
                    style={{
                        backgroundImage: `url(/img/${leftBackground}${
                            width < 1024 ? "_mobile" : ""
                        }.svg)`,
                    }}
                />
                <RightBackground
                    style={{
                        backgroundImage: `url(/img/${rightBackground}${
                            width < 1024 ? "_mobile" : ""
                        }.svg)`,
                    }}
                />
            </Content>
        </Holder>
    );
}
