import React from "react";
import styled from "styled-components";

import GameHeader from "../blocks/game_header";
import GameInfo from "../blocks/game_info";
import DankPlayable from "./playable/dank";

const Holder = styled.div``;

export default function Dank() {
    return (
        <Holder>
            <GameHeader
                title="DANK"
                video="/videos/dank.mp4"
                rotation={3}
                bottom={0}
                icon="/img/game_logo_dank.svg"
                color1="#344BFF"
                color2="#00F088"
            />
            <GameInfo
                icon="/img/icon_2.svg"
                color1="#344BFF"
                title={"Dank"}
                content={
                    "Melhor do que estar em uma quadra da NBA, é jogar com várias cestas de basquetes tecnológicas."
                }
                habilidades={["Agilidade", "Precisão", "Raciocinio"]}
                regras={[
                    "Time de 2 a 6 pessoas",
                    "Arrisque uma de 3",
                    "Todas as idades",
                ]}
            >
                <DankPlayable />
            </GameInfo>
        </Holder>
    );
}
