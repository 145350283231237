import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Target from "./components/target";

const Holder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  color: #000;
`;

const MainHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 96px 48px;
  position: relative;
  z-index: 0;
  top: 0px;
`;

const ChoicesHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;

  svg {
    margin: 8px;
  }
`;

function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

export default function HitPlayable() {
  let colors = ["#00F088", "#344BFF", "#FF4824", "#FF66FF", "#FF4824"];

  const [right, setRight] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [color, setColor] = useState();
  const [_colors, setColors] = useState([]);

  const hit = (_color) => {
    if (_color === color) {
      setRight(true);
      setTimeout(() => {
        setRight(false);
        startGame();
      }, 2000);
    } else {
      setWrong(true);
      setTimeout(() => {
        setWrong(false);
        startGame();
      }, 2000);
    }
  };

  const startGame = () => {
    const _color = colors[Math.floor(Math.random() * colors.length)];
    shuffle(colors);
    setColors(colors);
    setColor(_color);
  };

  useEffect(() => {
    startGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Holder id="hit">
      <MainHolder>
        <Target
          height={160}
          color={right ? "#2eff00" : wrong ? "#ff0000" : color}
        />
      </MainHolder>
      <ChoicesHolder>
        {_colors.map((_color, i) => {
          return (
            <Target
              hand
              callback={hit}
              color={right ? "#2eff00" : wrong ? "#ff0000" : _color}
            />
          );
        })}
      </ChoicesHolder>
    </Holder>
  );
}
