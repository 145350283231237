import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Wrapper from "../../components/user/wrapper";
import Title from "../../components/user/title";
import Text from "../../components/user/text";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Api from "../../libs/api";
import Storage from "../../libs/storage";
import Fields from "../../components/user/fields";
import TwoFactor from "../../components/user/twofactor";

const Holder = styled.div`
  padding: 48px;
  height: 100%;
  position: relative;
  flex: 1;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

export default function Login() {
  const { transaction } = useParams();
  const navigate = useNavigate();

  const api = Api();
  const storage = Storage();

  const [error, setError] = useState();
  const [email, setEmail] = useState();
  const [digits, setDigits] = useState();

  const [step, setStep] = useState(0);

  const checkEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const login = async () => {
    if (step === 0) {
      if (checkEmail()) {
        setError(null);
        await api.requestCode(email);
        setStep(1);
      } else {
        setError("E-mail inválido");
      }
    }
    if (step === 1) {
      const data = await api.validateCode(digits);
      if (data.token) {
        storage.saveItem("token", data.token);
        navigate("/user/tickets/");
      } else {
        setError("Código inválido");
      }
    }
  };

  useEffect(() => {
    if (digits && digits.length === 6 && step === 1) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digits]);

  const getData = async () => {
    if (transaction) {
      const data = await api.loginWithTransaction(transaction);
      if (data.token) {
        storage.saveItem("token", data.token);
        navigate("/user/setup/");
      } else {
        navigate("/user/login/");
      }
    } else {
      navigate("/user/login/");
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  return (
    <Wrapper
      backgroundLeft={"#000"}
      backgroundRight={"#344BFF"}
      footerType={2}
      login={true}
    >
      {step === 0 && (
        <Holder>
          <Title color={"#fff"}>Qual o seu e-mail?</Title>
          <Text color={"#fff"}>
            Use o e-mail que você efetuou suas compras.
          </Text>
          <Fields
            value={email}
            setValue={setEmail}
            color={"#00F088"}
            placeholder={"E-mail"}
            error={error}
            callback={login}
          />
        </Holder>
      )}
      {step === 1 && (
        <Holder>
          <Title color={"#fff"}>Insira o código</Title>
          <Text color={"#fff"}>
            Enviamos um código de uso único no seu e-mail. {digits}
          </Text>
          <TwoFactor error={error} callback={setDigits} />
        </Holder>
      )}
    </Wrapper>
  );
}
