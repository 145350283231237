import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Wrapper from "../../components/user/wrapper";
import Title from "../../components/user/title";
import Text from "../../components/user/text";
import Button from "../../components/user/button";
import { useNavigate } from "react-router-dom";
import Fields from "../../components/user/fields";
import Steps from "../../components/user/steps";
import Api from "../../libs/api";
import Storage from "../../libs/storage";
import FlagSelector from "../../components/user/flagselector";

import Helper from "../../libs/helper";
import TeamSelector from "../../components/user/teamselector";

const Holder = styled.div`
  padding: 48px;
  height: 100%;
  position: relative;
  flex: 1;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50%;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const Image = styled.img`
  position: absolute;
  right: -115px;
  top: 40%;
  z-index: 2;
  height: 230px;

  @media (max-width: 1024px) {
    position: relative;
    right: auto;
    top: auto;
    height: 170px;
  }
`;

const Centered = styled.div`
  width: 100%;
  padding: 48px;
  height: 100%;
  position: relative;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

const Flag = styled.div`
  display: flex;
  background: #000;
  align-items: start;
  justify-content: space-between;
  margin-top: 40px;
`;

const FlagName = styled.div`
  width: 100%;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 16px;
  display: flex;
  align-items: end;
  justify-content: start;
  width: 100%;
  height: 188px;
  flex: 1;
  word-break: break-all;

  @media (max-width: 1024px) {
    height: 144px;
  }
`;
const FlagImage = styled.img`
  width: 188px;
  height: 188px;

  @media (max-width: 1024px) {
    height: 144px;
    width: 144px;
  }
`;

const DateHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Date = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebdf35;

  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;

  @media (max-width: 1024px) {
    height: 72px;
    font-size: 24px;
  }
`;
const Time = styled.div`
  width: 188px;
  min-width: 188px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f471fd;
  text-transform: capitalize;

  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;

  @media (max-width: 1024px) {
    width: 144px;
    min-width: 144px;
    height: 72px;
    font-size: 24px;
  }
`;

export default function Setup() {
  // const navigate = useNavigate();

  const api = Api();
  const storage = Storage();
  const [step, setStep] = useState(0);
  const [user, setUser] = useState();
  const [surname, setSurname] = useState();
  const [team, setTeam] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [flag, setFlag] = useState(0);
  const [hash, setHash] = useState();

  const [error, setError] = useState();

  const navigate = useNavigate();

  const helper = Helper();

  const stepAction = async () => {
    const token = storage.getItem("token");
    if (step === 2) {
      if (token) {
        const data = await api.updateProfile(token, surname);
        if (data.error) {
          if (data.error === "Surname is the same") {
            setStep(3);
          }
          if (data.error === "Surname not allowed") {
            setError("Este nome não pode ser usado");
          }
        } else {
          setStep(3);
        }
      }
    }
    if (step === 3) {
      if (token) {
        const data = await api.createTeam(token, team, flag);
        if (data.error) {
          if (data.error === "Name not allowed") {
            setError("Este nome não pode ser usado");
          }
          if (data.error === "Name already taken") {
            setError("Este nome não pode ser usado");
          }
        } else {
          setHash(data.hash);
          setStep(4);
        }
      }
    }
    if (step === 4) {
      if (token) {
        const data = await api.updateTeam(token, hash, team, flag);
        if (data.error) {
          setError("Ocorreu um erro, tente novamente");
        } else {
          setStep(5);
        }
      }
    }
    if (step === 6) {
      if (token) {
        const data = await api.joinTeam(token, selectedTeam);
        if (data.error) {
          setError("Ocorreu um erro, tente novamente");
        } else {
          setStep(5);
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      setSurname(user.first_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (selectedTeam) {
      stepAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      backgroundLeft={step >= 4 ? "#FFF" : "#F471FD"}
      backgroundRight={step >= 4 ? "#DBDBDB" : "#000"}
      footerType={step === 6 ? 3 : step >= 4 ? 0 : 1}
      login={true}
      loginCallback={setUser}
    >
      <Holder>
        {step === 0 && (
          <>
            <Image src="/img/user/login_img_1.svg" />
            <Title>Deixe tudo sua cara!</Title>
            <Text>
              Seu time do seu jeito! Vamos escolher o nome e as cores para o seu
              time.
            </Text>
            <Bottom>
              <Button
                text={"Iniciar!"}
                color={"#000"}
                background={"#00F088"}
                callback={() => {
                  setStep(1);
                }}
              />
              {user && user.teams.length > 0 && (
                <Button
                  text={"Pular esta etapa"}
                  skip
                  color={"#000"}
                  callback={() => {
                    navigate("/user/tickets/");
                  }}
                />
              )}
            </Bottom>
          </>
        )}
        {step === 1 && (
          <>
            {user && user.teams.length > 0 && (
              <Button
                text={"Pular esta etapa"}
                skip
                color={"#000"}
                start
                callback={() => {
                  setStep(6);
                }}
              />
            )}
            <Title>Como personalizar</Title>
            <Text>
              Você pode criar um time do zero ou escolher entre seus times já
              existentes.
            </Text>
            <Bottom style={{ width: "100%" }}>
              <Button
                text={"Criar do zero"}
                color={"#fff"}
                background={"#000"}
                callback={() => {
                  setStep(2);
                }}
              />
              {user && user.teams.length > 0 && (
                <Button
                  text={"Escolher time existente"}
                  color={"#000"}
                  background={"#fff"}
                  callback={() => {
                    setStep(6);
                  }}
                />
              )}
            </Bottom>
          </>
        )}
        {step === 2 && (
          <>
            <Button
              text={"Pular esta etapa"}
              skip
              color={"#000"}
              start
              callback={() => {
                setStep(3);
              }}
            />
            <Title>Crie um apelido pra você</Title>
            <Text>Como você quer ser chamado aqui na Arena Pixel?</Text>
            <Fields
              color={"#00F088"}
              placeholder={"Apelido"}
              value={surname}
              setValue={setSurname}
              error={error}
              callback={() => {
                stepAction();
              }}
            />
            <Button
              text={"Gerar nome aleatório"}
              skip
              start
              color={"#000"}
              callback={() => {
                setSurname(helper.generateRandomSurName());
              }}
            />
            <Bottom style={{ width: "100%" }}>
              <Steps step={step} />
            </Bottom>
          </>
        )}
        {step === 3 && (
          <>
            {user && user.teams.length > 0 && (
              <Button
                text={"Pular esta etapa"}
                skip
                color={"#000"}
                start
                callback={() => {
                  setStep(6);
                }}
              />
            )}
            <Title>Crie o nome do time</Title>
            <Text>
              Solte a imaginação e escreva um nome divertido para o seu time!
              Exemplo: esquilos galácticos.
            </Text>
            <Fields
              color={"#00F088"}
              placeholder={"Nome do time"}
              value={team}
              setValue={setTeam}
              error={error}
              callback={() => {
                stepAction();
              }}
            />
            <Button
              text={"Gerar nome aleatório"}
              skip
              start
              color={"#000"}
              callback={() => {
                setTeam(helper.generateRandomTeamName());
              }}
            />
            <Bottom style={{ width: "100%" }}>
              <Steps step={step} />
            </Bottom>
          </>
        )}
        {step === 4 && (
          <>
            <Button
              text={"Pular esta etapa"}
              skip
              color={"#000"}
              start
              callback={() => {
                navigate("/user/tickets/");
              }}
            />
            <Title>Bandeira</Title>
            <Text>Selecione um avatar para compor a bandeira do seu time.</Text>
            <Flag>
              <FlagName>{team}</FlagName>
              <FlagImage src={`/img/flags/flag${flag}.svg`} alt="Bandeira" />
            </Flag>
            {width < 1024 && (
              <FlagSelector
                team={team}
                flag={flag}
                setFlag={setFlag}
                topMargin={32}
                bottomMargin={184}
              />
            )}
            <Bottom style={{ width: "100%" }}>
              <Button
                text={"Concluir criação"}
                color={"#fff"}
                background={"#000"}
                callback={() => {
                  stepAction();
                }}
              />
              <Steps step={step} />
            </Bottom>
          </>
        )}
        {step === 5 && (
          <>
            <Title>
              Time {selectedTeam ? "selecionado" : "criado"} com sucesso!
            </Title>
            <Text>Confira abaixo os detalhes da sua reserva</Text>
            <Flag>
              <FlagName>{team}</FlagName>
              <FlagImage src={`/img/flags/flag${flag}.svg`} alt="Bandeira" />
            </Flag>
            <DateHolder>
              <Date>
                {
                  helper.formatDate(user.tickets[0].initial_validity)
                    .formattedDate
                }
              </Date>
              <Time>
                {
                  helper.formatDate(user.tickets[0].initial_validity)
                    .formattedDay
                }{" "}
                {
                  helper.formatDate(user.tickets[0].initial_validity)
                    .formattedTime
                }
              </Time>
            </DateHolder>
            <Bottom style={{ width: "100%" }}>
              <Button
                text={"Minhas reservas"}
                color={"#fff"}
                background={"#00F088"}
                callback={() => {
                  navigate("/user/tickets/");
                }}
              />
            </Bottom>
          </>
        )}
        {step === 6 && (
          <>
            <Button
              text={"Pular esta etapa"}
              skip
              color={"#000"}
              start
              callback={() => {
                navigate("/user/tickets/");
              }}
            />
            <Title>Quer escolher um time do coração?</Title>
            <Text>Você pode escolher de um time já existente.</Text>
            {width < 1024 && (
              <TeamSelector
                teams={user.teams}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
                setTeam={setTeam}
              />
            )}
          </>
        )}
      </Holder>
      {/* {step < 4 && <Centered></Centered>} */}
      {step === 4 && (
        <Centered>
          <FlagSelector team={team} flag={flag} setFlag={setFlag} />
        </Centered>
      )}
      {step === 6 && (
        <Centered>
          {user && user.teams.length > 0 && (
            <TeamSelector
              teams={user.teams}
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
              setTeam={setTeam}
            />
          )}
        </Centered>
      )}
    </Wrapper>
  );
}
